.App {
  width: 100%;
  background-color: #212121;
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.under {
  background-color: #f2f2f2;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fefefe;
}

.under-img {
  width: 100%;
}

.title {
  font-size: 17px;
  color: #444;
  text-transform: uppercase;
  font-weight: 700;
}

a {
  text-decoration: none;
  color: #fefefe;
}

.py-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #616161;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #bdbdbd;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
}
